import React, { useState, useEffect } from "react";
import "../../css/CustomStyles.css";
import { Api } from "../../remote/api";
import Popup from "../../lib/popup";

export const Tab2Content = () => {
    const [stormModeSwitch, setStormModeSwitch] = useState(false);
    const [stormModeEnabled, setStormModeEnabled] = useState(false);
    const [stormModeMessage, setStormModeMessage] = useState(
      "The Entergy Mobile App is in Storm Mode to maintain availability during high demand. Please select an option below to continue."
    );
    const [stormModeOpcos, setStormModeOpcos] = useState(["All Opcos"]);
    const [maintenanceSwitch, setMaintenanceSwitch] = useState(false);
    const [maintenanceModeEnabled, setMaintenanceModeEnabled] = useState(false);
    const [maintenanceModeMessage, setMaintenanceModeMessage] = useState(
      "Entergy mobile app is currently unavailable while we complete maintenance and improvements to the app. We are working to complete this as quickly as possible and apologize for any inconvenience."
    );
    const [sliderValue, setSliderValue] = useState(0);
    const [sliderValue2, setSliderValue2] = useState(0);
    const [stormSliderValue1, setStormSliderValue1] = useState(0);
    const [stormSliderValue2, setStormSliderValue2] = useState(0);
    const [confirmationModalValue, SetConfirmationModalValue] = useState("");
    const [confirmationModalAction, SetConfirmationModalAction] = useState("");
    const [maintenanceModeTemplates, SetMaintenanceModeTemplates] = useState([]);
    const [stormModeTemplates, SetStormModeTemplates] = useState([]);
    const [urgentMessageTemplates, SetUrgentMessageTemplates] = useState({
      home: [],
      bill: [],
      outage: [],
      usage: [],
      more: [],
    });
    const [urgentMessages, setUrgentMessages] = useState({
      Home: {
        enabled: false,
        title: "",
        message: "",
        opc: ["All Opcos"],
        url: "",
        services: ["All Services"]
      },
      Bill: {
        enabled: false,
        title: "",
        message: "",
        opc: ["All Opcos"],
        url: "",
        services: ["All Services"]
      },
      Outage: {
        enabled: false,
        title: "",
        message: "",
        opc: ["All Opcos"],
        url: "",
        services: ["All Services"]
      },
      Usage: {
        enabled: false,
        title: "",
        message: "",
        opc: ["All Opcos"],
        url: "",
        services: ["All Services"]
      },
    });
    const [disabledPowerStatusOpcos, SetDisabledPowerStatusOpcos] = useState([
      "All Opcos",
    ]);
    const [disabledPowerStatusMessage, SetDisabledPowerStatusMessage] =
      useState("");
    const [disabledPowerStatus, SetDisabledPowerStatus] = useState(false);
    const [newFeature, SetNewFeature] = useState({
      id: "",
      ctaText: "",
      startDate: "",
      endDate: "",
      title: "",
      titleIOS: "",
      titleAndroid: "",
      message: "",
      messageIOS: "",
      messageAndroid: "",
      type: "",
      url: "",
    });
  
    function capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  
    const fillUpOpcoList = (opcos) => {
      let opc = ["All Opcos"];
      let containsAllValues = true;
      const possibleOpcos = [4000, 5000, 6000, 7000, 8000, 9000];
      containsAllValues = possibleOpcos.every(
        (value) => opcos.includes(value) || opcos.includes(value.toString())
      );
      if (!containsAllValues) {
        opc = [];
        opcos.forEach((opco) => {
          opc.push(opco.toString());
        });
      }
      return opc;
    };

    const fillUpServiceList = (services) => {
      let serviceList = ["All Services"];
      let containsAllValues = true;
      const possibleOpcos = ['electric', 'light', 'gas'];
      containsAllValues = possibleOpcos.every(
        (value) => services.includes(value) 
      );
      if (!containsAllValues) {
        serviceList = [];
        services.forEach((service) => {
          serviceList.push(service);
        });
      }
      return serviceList;
    };
  
    const getTemplates = async () => {
      const result = await Api.getTemplates();

      if (result && result.stormModeTemplates.length > 0) {
        SetStormModeTemplates(result.stormModeTemplates);
      }
  
      if (result && result.maintenanceModeTemplates.length > 0) {
        SetMaintenanceModeTemplates(result.maintenanceModeTemplates);
      }
  
      if (result && result.urgentMessageTemplates) {
        SetUrgentMessageTemplates((prevState) => ({
          ...prevState,
          ...result.urgentMessageTemplates,
        }));
      }
    };
  
    const getAppConfig = async () => {
      const result = await Api.getAppConfig();
  
      const newUrgentMessage = result.newUrgentMessage;
      const maintenancePeriods = result.maintenancePeriods;
      const disabledPowerStatus = result.disablePowerStatusMessage;
      const newFeature = result.newFeature;
      const stormMode = result.stormMode;
  
      if (newFeature && newFeature.id && newFeature.message) {
        SetNewFeature(newFeature);
      }
  
      if (
        disabledPowerStatus &&
        disabledPowerStatus.opcos &&
        disabledPowerStatus.opcos.length > 0
      ) {
        SetDisabledPowerStatus(true);
        if (disabledPowerStatus.message) {
          SetDisabledPowerStatusMessage(disabledPowerStatus.message);
        }
        const opcos = fillUpOpcoList(disabledPowerStatus.opcos);
        SetDisabledPowerStatusOpcos(opcos);
      }
  
      if (maintenancePeriods && maintenancePeriods.length > 0) {
        const maintenancePeriod = maintenancePeriods[0];
        setMaintenanceModeMessage(maintenancePeriod.message);
        setMaintenanceModeEnabled(true);
      } else {
        setMaintenanceModeEnabled(false);
      }

      if (stormMode && stormMode.isEnabled) {
        setStormModeMessage(stormMode.message)
        setStormModeEnabled(true);
        const opcos = fillUpOpcoList(stormMode.opcos);
        setStormModeOpcos(opcos);
      } else {
        setStormModeEnabled(false);
      }
  
      if (newUrgentMessage) {
        newUrgentMessage.forEach((message) => {
          if (message.id && message.message && message.title) {
            let services = ["All Services"];
            let opc = ["All Opcos"];
            let url = "";
            let capitalizedTab;
            if (message.tab) {
              capitalizedTab = capitalizeFirstLetter(message.tab[0]);
            }
            if (!capitalizedTab) {
              capitalizedTab = "Home";
            }
            if (message.opcos) {
              opc = fillUpOpcoList(message.opcos);
            }
            if (message.services) {
              services = fillUpServiceList(message.services)
            }
            if (message.url) {
              url = message.url;
            }
            setUrgentMessages((prevState) => ({
              ...prevState,
              [capitalizedTab]: {
                ...prevState[capitalizedTab],
                enabled: true,
                title: message.title,
                message: message.message,
                opc: opc,
                url: url,
                services: services
              },
            }));
          }
        });
      }
    };
  
    useEffect(() => {
      getAppConfig();
      getTemplates();
    }, []);

    const handleStormSliderChange = (event) => {
      const value = parseInt(event.target.value);
      setStormSliderValue1(value);
      if (value >= 100) {
        setStormModeSwitch(true);
      }
    };
  
    const handleStormSliderRelease = () => {
      //setSliderValue(0);
    };
  
    const handleStormSliderEnd = () => {
      //setSliderValue(0);
    };
  
    const handleSliderChange = (event) => {
      const value = parseInt(event.target.value);
      setSliderValue(value);
      if (value >= 100) {
        setMaintenanceSwitch(true);
      }
    };
  
    const handleSliderRelease = () => {
      //setSliderValue(0);
    };
  
    const handleSliderEnd = () => {
      //setSliderValue(0);
    };

    const handleStormSliderChange2 = async (event) => {
      const value = parseInt(event.target.value);
      setStormSliderValue2(value);
  
      if (value >= 100 && stormModeSwitch) {
        setStormModeSwitch(false);
        if (stormModeEnabled) {
          console.log("TRYING TO EXIT STORM MODE...");
          const stormMode = {
            isEnabled: false,
            title: '',
            message: '',
            opcos: []
          };
          const result = await Api.updateAppConfig(stormMode, 'stormMode');
          if (result) {
            console.log("STORM MODE EXITED");
            setStormModeEnabled(false);
          }
        } else {
          console.log("TRYING TO ENTER STORM MODE...");
          let opcos = [];
  
          if (stormModeOpcos.includes("All Opcos")) {
            opcos = ["4000", "5000", "6000", "7000", "8000", "9000"];
          } else {
            opcos = stormModeOpcos;
          }
          const stormMode = {
            isEnabled: true,
            title: "Storm Mode",
            message: stormModeMessage,
            opcos
          };
          const result = await Api.updateAppConfig(stormMode, 'stormMode');
          if (result) {
            console.log("STORM MODE MODE SUCCESSFUL");
            setStormModeEnabled(true);
          }
        }
      }
    };
  
    const handleStormSliderRelease2 = () => {
      setStormSliderValue1(0);
      setStormSliderValue2(0);
    };
  
    const handleStormSliderEnd2 = () => {
      setStormSliderValue1(0);
      setStormSliderValue2(0);
    };
  
    const handleSliderChange2 = async (event) => {
      const value = parseInt(event.target.value);
      setSliderValue2(value);
  
      if (value >= 100 && maintenanceSwitch) {
        setMaintenanceSwitch(false);
        if (maintenanceModeEnabled) {
          console.log("TRYING TO EXIT MAINTENANCE...");
          const maintenancePeriods = [];
          const result = await Api.updateAppConfig(maintenancePeriods, 'maintenancePeriods');
          if (result) {
            console.log("MAINTENANCE EXITED");
            setMaintenanceModeEnabled(false);
          }
        } else {
          console.log("TRYING TO ENTER MAINTENANCE...");
          const maintenance = {
            startDate: "2021-01-07T02:00:00.000Z",
            message: maintenanceModeMessage,
          };
          const maintenancePeriods = [maintenance];
          const result = await Api.updateAppConfig(maintenancePeriods, 'maintenancePeriods');
          if (result) {
            console.log("MAINTENANCE MODE SUCCESSFUL");
            setMaintenanceModeEnabled(true);
          }
        }
      }
    };
  
    const handleSliderRelease2 = () => {
      setSliderValue(0);
      setSliderValue2(0);
    };
  
    const handleSliderEnd2 = () => {
      setSliderValue(0);
      setSliderValue2(0);
    };
  
    const handleMaintenanceModeMessageChange = (event) => {
      setMaintenanceModeMessage(event.target.value);
    };

    const handleStormModeMessageChange = (event) => {
      setStormModeMessage(event.target.value);
    };
  
    const handleUrgentMessageTitleChange = (section, event) => {
      setUrgentMessages((prevUrgentMessages) => ({
        ...prevUrgentMessages,
        [section]: {
          ...prevUrgentMessages[section],
          title: event.target.value,
        },
      }));
    };
  
    const handleDisabledPowerStatusMessageChange = (event) => {
      const message = event.target.value;
      SetDisabledPowerStatusMessage(message);
    };
  
    const handleUrgentMessageContentChange = (section, event) => {
      setUrgentMessages((prevUrgentMessages) => ({
        ...prevUrgentMessages,
        [section]: {
          ...prevUrgentMessages[section],
          message: event.target.value,
        },
      }));
    };
  
    const handleUrgentMessageUrlChange = (section, event) => {
      setUrgentMessages((prevUrgentMessages) => ({
        ...prevUrgentMessages,
        [section]: {
          ...prevUrgentMessages[section],
          url: event.target.value,
        },
      }));
    };

    const handleStormModeOpcoChange = (event) => {
      const selectedOpc = event.target.value;
      const isChecked = event.target.checked;
      
      setStormModeOpcos((prevStormModeOpcos) =>
        updateOpcSelection(prevStormModeOpcos, selectedOpc, isChecked)
      );
    };
  
    const handleDisabledPowerStatusOpcoChange = (event) => {
      const selectedOpc = event.target.value;
      const isChecked = event.target.checked;
  
      SetDisabledPowerStatusOpcos((prevPowerStatusOpcos) =>
        updateOpcSelection(prevPowerStatusOpcos, selectedOpc, isChecked)
      );
    };
  
    const handleUrgentMessageOpcChange = (section, event) => {
      const selectedOpc = event.target.value;
      const isChecked = event.target.checked;
  
      setUrgentMessages((prevUrgentMessages) => ({
        ...prevUrgentMessages,
        [section]: {
          ...prevUrgentMessages[section],
          opc: updateOpcSelection(
            prevUrgentMessages[section].opc,
            selectedOpc,
            isChecked
          ),
        },
      }));
    };

    const handleUrgentMessageServiceChange = (section, event) => {
      const selectedService = event.target.value;
      const isChecked = event.target.checked;
  
      setUrgentMessages((prevUrgentMessages) => ({
        ...prevUrgentMessages,
        [section]: {
          ...prevUrgentMessages[section],
          services: updateServiceSelection(
            prevUrgentMessages[section].services,
            selectedService,
            isChecked
          ),
        },
      }));
    };
  
    const updateOpcSelection = (opcList, selectedOpc, isChecked) => {
      if (selectedOpc === "All Opcos") {
        const update = isChecked ? ["All Opcos"] : [];
        return update;
      }
  
      if (isChecked) {
        if (!opcList.includes(selectedOpc)) {
          opcList = [...opcList, selectedOpc];
          opcList = opcList.filter((opco) => opco !== "All Opcos");
          return opcList;
        }
      } else {
        const update = opcList.filter((opco) => opco !== selectedOpc);
        return update;
      }
      return opcList;
    };

    const updateServiceSelection = (serviceList, selectedService, isChecked) => {
      if (selectedService === "All Services") {
        const update = isChecked ? ["All Services"] : [];
        return update;
      }
  
      if (isChecked) {
        if (!serviceList.includes(selectedService)) {
          serviceList = [...serviceList, selectedService];
          serviceList = serviceList.filter((service) => service !== "All Services");
          return serviceList;
        }
      } else {
        const update = serviceList.filter((service) => service !== selectedService);
        return update;
      }
      return serviceList;
    };
  
    const handleRemoveDisabledPowerStatus = async () => {
      SetDisabledPowerStatus(false);
  
      const disablePowerStatusMessage = {
        message: "",
        opcos: [],
      };
  
      const result = await Api.updateAppConfig(
        disablePowerStatusMessage,
        'disablePowerStatusMessage'
      );
  
      if (result) {
        await getAppConfig();
      }
    };
  
    const handlePublishDisabledPowerStatus = async () => {
      let opcos = [];
  
      if (disabledPowerStatusOpcos.includes("All Opcos")) {
        opcos = ["4000", "5000", "6000", "7000", "8000", "9000"];
      } else {
        disabledPowerStatusOpcos.forEach((opco) => {
          opcos.push(opco);
        });
      }
  
      const disablePowerStatusMessage = {
        message: disabledPowerStatusMessage,
        opcos,
      };
  
      const result = await Api.updateAppConfig(
        disablePowerStatusMessage,
        'disablePowerStatusMessage'
      );
  
      if (result) {
        console.log("UPDATED", JSON.stringify(disablePowerStatusMessage));
        await getAppConfig();
      }
    };
  
    const handlePublishUrgentMessage = async (section) => {
      setUrgentMessages((prevUrgentMessages) => ({
        ...prevUrgentMessages,
        [section]: {
          ...prevUrgentMessages[section],
          enabled: true,
        },
      }));
  
      const newUrgentMessage = [];
  
      Object.keys(urgentMessages).forEach((tab) => {
        let enabled;
  
        if (tab === section) {
          enabled = true;
        } else {
          enabled = urgentMessages[tab].enabled;
        }
        if (enabled) {
          let opcos = [];
          let services = [];
          if (urgentMessages[tab].opc.includes("All Opcos")) {
            opcos = [4000, 5000, 6000, 7000, 8000, 9000];
          } else {
            urgentMessages[tab].opc.forEach((opco) => {
              opcos.push(parseInt(opco, 10));
            });
          }
          if (urgentMessages[tab].services.includes("All Services")) {
            services = ['electric', 'light', 'gas'];
          } else {
            urgentMessages[tab].services.forEach((service) => {
              services.push(service);
            });
          }
          const lowercasedTab = tab.toLowerCase();
          const urgentMessage = {
            id: "UrgentMessage",
            tab: lowercasedTab !== "home" ? [lowercasedTab] : null,
            title: urgentMessages[tab].title,
            message: urgentMessages[tab].message,
            url: urgentMessages[tab].url,
            opcos: opcos,
            services: services
          };
          newUrgentMessage.push(urgentMessage);
        }
      });
  
      const result = await Api.updateAppConfig(newUrgentMessage, 'newUrgentMessage');
  
      if (result) {
        console.log("UPDATED", JSON.stringify(urgentMessages));
        await getAppConfig();
      }
    };
  
    const handleRemoveUrgentMessage = async (section) => {
      setUrgentMessages((prevUrgentMessages) => ({
        ...prevUrgentMessages,
        [section]: {
          ...prevUrgentMessages[section],
          enabled: false,
        },
      }));
  
      const newUrgentMessage = [];
  
      Object.keys(urgentMessages).forEach((tab) => {
        let enabled;
  
        if (tab === section) {
          enabled = false;
        } else {
          enabled = urgentMessages[tab].enabled;
        }
        if (enabled) {
          let opcos = [];
          let services = [];
          if (urgentMessages[tab].opc.includes("All Opcos")) {
            opcos = [4000, 5000, 6000, 7000, 8000, 9000];
          } else {
            urgentMessages[tab].opc.forEach((opco) => {
              opcos.push(parseInt(opco, 10));
            });
          }
          if (urgentMessages[tab].services.includes("All Services")) {
            services = ['electric', 'light', 'gas'];
          } else {
            urgentMessages[tab].services.forEach((service) => {
              services.push(service);
            });
          }
          const urgentMessage = {
            id: "UrgentMessage",
            tab: [tab.toLowerCase()],
            title: urgentMessages[tab].title,
            message: urgentMessages[tab].message,
            url: urgentMessages[tab].url,
            opcos: opcos,
            services: services
          };
          console.log("PUSHING", JSON.stringify(urgentMessage));
          newUrgentMessage.push(urgentMessage);
        }
      });
  
      console.log("PUSHED", JSON.stringify(newUrgentMessage));
  
      const result = await Api.updateAppConfig(newUrgentMessage, 'newUrgentMessage');
  
      if (result) {
        console.log("UPDATED", JSON.stringify(urgentMessages));
        await getAppConfig();
      }
    };
  
    const onCancelConfirmation = (action, section) => {
      SetConfirmationModalValue("");
    };
  
    const handleRemoveNewFeature = async () => {
      SetNewFeature((prevNewFeature) => ({
        ...prevNewFeature,
        id: "",
      }));
  
      const newFeatureEmpty = {};
  
      const result = await Api.updateAppConfig(newFeatureEmpty, 'newFeatureEmpty');
  
      if (result) {
        console.log("UPDATED", JSON.stringify(newFeatureEmpty));
        await getAppConfig();
      }
    };
  
    const handlePublishNewFeature = async () => {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      newFeature.id = `newfeature${timestamp}`;
      newFeature.startDate = "2020-11-18T00:00:00.000Z";
      newFeature.endDate = "4000-12-31T00:00:00.000Z";
  
      const result = await Api.updateAppConfig(newFeature, 'newFeature');
  
      if (result) {
        console.log("UPDATED", JSON.stringify(newFeature));
        await getAppConfig();
      }
    };
  
    const onContinueConfirmation = (action, section) => {
      if (confirmationModalAction === "Publish") {
        if (confirmationModalValue === "disabledPowerStatus") {
          handlePublishDisabledPowerStatus();
        } else if (confirmationModalValue === "newFeature") {
          handlePublishNewFeature();
        } else if (confirmationModalValue === "maintenanceMode") {
          //handlePublishNewFeature();
        } else {
          handlePublishUrgentMessage(section);
        }
      } else if (confirmationModalAction === "Remove") {
        if (confirmationModalValue === "disabledPowerStatus") {
          handleRemoveDisabledPowerStatus();
        } else if (confirmationModalValue === "newFeature") {
          handleRemoveNewFeature();
        } else if (confirmationModalValue === "maintenanceMode") {
          //handleRemoveNewFeature();
        } else {
          handleRemoveUrgentMessage(section);
        }
      }
  
      SetConfirmationModalValue("");
    };
  
    const showConfirmRemove = (section) => {
      SetConfirmationModalAction("Remove");
      SetConfirmationModalValue(section);
    };
  
    const showConfirmPublish = (section) => {
      SetConfirmationModalAction("Publish");
      SetConfirmationModalValue(section);
    };
  
    const handleNewFeatureTitleChangeIOS = (event) => {
      SetNewFeature((prevNewFeature) => ({
        ...prevNewFeature,
        title: event.target.value,
        titleIOS: event.target.value,
      }));
    };
  
    const handleNewFeatureMessageChangeIOS = (event) => {
      SetNewFeature((prevNewFeature) => ({
        ...prevNewFeature,
        message: event.target.value,
        messageIOS: event.target.value,
      }));
    };

    const handleNewFeatureTitleChangeAndroid = (event) => {
      SetNewFeature((prevNewFeature) => ({
        ...prevNewFeature,
        titleAndroid: event.target.value,
      }));
    };
  
    const handleNewFeatureMessageChangeAndroid = (event) => {
      SetNewFeature((prevNewFeature) => ({
        ...prevNewFeature,
        messageAndroid: event.target.value,
      }));
    };
  
    const handleNewFeatureCTAChange = (event) => {
      SetNewFeature((prevNewFeature) => ({
        ...prevNewFeature,
        ctaText: event.target.value,
      }));
    };
  
    const handleNewFeatureURLChange = (event) => {
      SetNewFeature((prevNewFeature) => ({
        ...prevNewFeature,
        url: event.target.value,
      }));
    };
  
    const handleMaintenanceModeTemplateChange = (event) => {
      const template = event.target.value;
  
      if (template === "Select a template") {
        return;
      }
  
      const templateObject = maintenanceModeTemplates.find((obj) =>
        obj.hasOwnProperty(template)
      );
  
      setMaintenanceModeMessage(templateObject[template]);
    };

    const handleStormModeTemplateChange = (event) => {
      const template = event.target.value;
  
      if (template === "Select a template") {
        return;
      }
  
      const templateObject = stormModeTemplates.find((obj) =>
        obj.hasOwnProperty(template)
      );
  
      setStormModeMessage(templateObject[template]);
    };
  
    const handleUrgentMessageTemplateChange = (section, event) => {
      const lowercasedSection = section.toLowerCase();
      const template = event.target.value;
  
      if (template === "Select a template") {
        return;
      }
  
      const sectionMessages = urgentMessageTemplates[lowercasedSection];
  
      const templateObject = sectionMessages.find((obj) =>
        obj.hasOwnProperty(template)
      );
  
      setUrgentMessages((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          title: templateObject[template].title,
          message: templateObject[template].message,
          url: templateObject[template].url,
        },
      }));
    };
  
    return (
      <div className="tab2-container">
        <div>
          {confirmationModalValue !== "" && (
            <Popup
              message={"Are you sure you would like to apply this change?"}
              action={confirmationModalAction}
              value={confirmationModalValue}
              onCancel={onCancelConfirmation}
              onContinue={onContinueConfirmation}
            />
          )}
        </div>
        <div
          className={`${maintenanceModeEnabled ? "test-fail" : "test-passed"}`}
        >
          <h2>
            Maintenance Mode Status:{" "}
            {maintenanceModeEnabled ? "Enabled" : "Disabled"}
          </h2>
        </div>
        <div>
          <label>Maintenance Mode Templates:</label>
          <select onChange={handleMaintenanceModeTemplateChange}>
            <option value="Select a template">Select a template</option>
            {maintenanceModeTemplates.map((template, index) => {
              const key = Object.keys(template)[0];
              return (
                <option key={index} value={key}>
                  {key}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <label>Maintenance Mode Custom Message:</label>
          <textarea
            className="urgent-message-message"
            type="text"
            value={maintenanceModeMessage}
            onChange={handleMaintenanceModeMessageChange}
          />
          <div>
            <h3>
              {maintenanceModeEnabled
                ? "Slide to exit Maintenance Mode"
                : "Slide to enter Maintenance Mode"}
            </h3>
            <input
              className="maintenance-mode-slider"
              type="range"
              min="0"
              max="100"
              value={sliderValue}
              onInput={handleSliderChange}
              onMouseUp={handleSliderRelease}
              onTouchEnd={handleSliderEnd}
            />
            <input
              className="maintenance-mode-slider"
              type="range"
              min="0"
              max="100"
              value={sliderValue2}
              onInput={handleSliderChange2}
              onMouseUp={handleSliderRelease2}
              onTouchEnd={handleSliderEnd2}
            />
          </div>
        </div>
        <br></br>
        <br></br>

        <div>
          <h2>Urgent Messages</h2>
          {Object.keys(urgentMessages).map((section) => (
            <div key={section} className="urgent-message">
              <div>
                <div
                  className={`${
                    urgentMessages[section].enabled ? "test-fail" : "test-passed"
                  }`}
                >
                  <label>
                    {section} Status:{" "}
                    {urgentMessages[section].enabled ? "Enabled" : "Disabled"}
                  </label>
                </div>
                <div>
                  <label>Urgent Message Templates:</label>
                  <select
                    onChange={(event) =>
                      handleUrgentMessageTemplateChange(section, event)
                    }
                  >
                    <option value="Select a template">Select a template</option>
                    {urgentMessageTemplates[section.toLowerCase()].map(
                      (template, index) => {
                        const key = Object.keys(template)[0];
                        return (
                          <option key={index} value={key}>
                            {key}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div>
                  <label>Title:</label>
                  <input
                    className="urgent-message-title"
                    type="text"
                    value={urgentMessages[section].title}
                    onChange={(event) =>
                      handleUrgentMessageTitleChange(section, event)
                    }
                  />
                </div>
                <div>
                  <label>Message:</label>
                  <textarea
                    className="urgent-message-message"
                    value={urgentMessages[section].message}
                    onChange={(event) =>
                      handleUrgentMessageContentChange(section, event)
                    }
                  />
                </div>
                <div>
                  <label>URL:</label>
                  <textarea
                    className="urgent-message-title"
                    value={urgentMessages[section].url}
                    onChange={(event) =>
                      handleUrgentMessageUrlChange(section, event)
                    }
                  />
                </div>
                <div>
                  <label>Opco:</label>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="All Opcos"
                        checked={urgentMessages[section].opc.includes(
                          "All Opcos"
                        )}
                        onChange={(event) =>
                          handleUrgentMessageOpcChange(section, event)
                        }
                      />
                      All Opcos
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="4000"
                        checked={urgentMessages[section].opc.includes("4000")}
                        onChange={(event) =>
                          handleUrgentMessageOpcChange(section, event)
                        }
                      />
                      4000
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="5000"
                        checked={urgentMessages[section].opc.includes("5000")}
                        onChange={(event) =>
                          handleUrgentMessageOpcChange(section, event)
                        }
                      />
                      5000
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="6000"
                        checked={urgentMessages[section].opc.includes("6000")}
                        onChange={(event) =>
                          handleUrgentMessageOpcChange(section, event)
                        }
                      />
                      6000
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="7000"
                        checked={urgentMessages[section].opc.includes("7000")}
                        onChange={(event) =>
                          handleUrgentMessageOpcChange(section, event)
                        }
                      />
                      7000
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="8000"
                        checked={urgentMessages[section].opc.includes("8000")}
                        onChange={(event) =>
                          handleUrgentMessageOpcChange(section, event)
                        }
                      />
                      8000
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="9000"
                        checked={urgentMessages[section].opc.includes("9000")}
                        onChange={(event) =>
                          handleUrgentMessageOpcChange(section, event)
                        }
                      />
                      9000
                    </label>
                  </div>
                  
                </div>
                <div>
                  <label>Services:</label>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="All Services"
                        checked={urgentMessages[section].services.includes(
                          "All Services"
                        )}
                        onChange={(event) =>
                          handleUrgentMessageServiceChange(section, event)
                        }
                      />
                      All Services
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="electric"
                        checked={urgentMessages[section].services.includes("electric")}
                        onChange={(event) =>
                          handleUrgentMessageServiceChange(section, event)
                        }
                      />
                      electric
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="light"
                        checked={urgentMessages[section].services.includes("light")}
                        onChange={(event) =>
                          handleUrgentMessageServiceChange(section, event)
                        }
                      />
                      light
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="gas"
                        checked={urgentMessages[section].services.includes("gas")}
                        onChange={(event) =>
                          handleUrgentMessageServiceChange(section, event)
                        }
                      />
                      gas
                    </label>
                  </div>
                </div>
                <div>
                  {urgentMessages[section].enabled ? (
                    <button
                      onClick={() => showConfirmRemove(section)}
                      className={`${
                        urgentMessages[section].enabled
                          ? "test-fail"
                          : "test-passed"
                      }`}
                    >
                      Remove {section} Urgent Message
                    </button>
                  ) : (
                    <button
                      onClick={() => showConfirmPublish(section)}
                      className={`${
                        urgentMessages[section].enabled
                          ? "test-fail"
                          : "test-passed"
                      }`}
                    >
                      Publish {section} Urgent Message
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <h2>Disabled Power Status</h2>
          <div>
            <div
              className={`${disabledPowerStatus ? "test-fail" : "test-passed"}`}
            >
              <label>
                Status: {disabledPowerStatus ? "Enabled" : "Disabled"}
              </label>
            </div>
          </div>
          <div>
            <label>Message:</label>
            <textarea
              className="urgent-message-title"
              value={disabledPowerStatusMessage}
              onChange={(event) => handleDisabledPowerStatusMessageChange(event)}
            />
          </div>
          <div>
            <label>Opco:</label>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="All Opcos"
                  checked={disabledPowerStatusOpcos.includes("All Opcos")}
                  onChange={(event) => handleDisabledPowerStatusOpcoChange(event)}
                />
                All Opcos
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="4000"
                  checked={disabledPowerStatusOpcos.includes("4000")}
                  onChange={(event) => handleDisabledPowerStatusOpcoChange(event)}
                />
                4000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="5000"
                  checked={disabledPowerStatusOpcos.includes("5000")}
                  onChange={(event) => handleDisabledPowerStatusOpcoChange(event)}
                />
                5000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="6000"
                  checked={disabledPowerStatusOpcos.includes("6000")}
                  onChange={(event) => handleDisabledPowerStatusOpcoChange(event)}
                />
                6000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="7000"
                  checked={disabledPowerStatusOpcos.includes("7000")}
                  onChange={(event) => handleDisabledPowerStatusOpcoChange(event)}
                />
                7000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="8000"
                  checked={disabledPowerStatusOpcos.includes("8000")}
                  onChange={(event) => handleDisabledPowerStatusOpcoChange(event)}
                />
                8000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="9000"
                  checked={disabledPowerStatusOpcos.includes("9000")}
                  onChange={(event) => handleDisabledPowerStatusOpcoChange(event)}
                />
                9000
              </label>
            </div>
          </div>
          <div>
            {disabledPowerStatus ? (
              <button
                onClick={() => showConfirmRemove("disabledPowerStatus")}
                className={`${disabledPowerStatus ? "test-fail" : "test-passed"}`}
              >
                Remove Custom Power Status
              </button>
            ) : (
              <button
                onClick={() => showConfirmPublish("disabledPowerStatus")}
                className={`${disabledPowerStatus ? "test-fail" : "test-passed"}`}
              >
                Publish Custom Power Status
              </button>
            )}
          </div>
        </div>
        <div>
          <h2>New Feature Card</h2>
          <div>
            <div
              className={`${newFeature.id !== "" ? "test-fail" : "test-passed"}`}
            >
              <label>
                Status: {newFeature.id !== "" ? "Enabled" : "Disabled"}
              </label>
            </div>
          </div>
        </div>
        <div>
        <h3>iOS Content:</h3>
          <label>iOS Title:</label>
          <textarea
            className="urgent-message-title"
            value={newFeature.titleIOS}
            onChange={(event) => handleNewFeatureTitleChangeIOS(event)}
          />
        </div>
        <div>
          <label>iOS Message:</label>
          <textarea
            className="urgent-message-message"
            value={newFeature.messageIOS}
            onChange={(event) => handleNewFeatureMessageChangeIOS(event)}
          />
        </div>
        <div>
        <h3>Android Content:</h3>
          <label>Android Title:</label>
          <textarea
            className="urgent-message-title"
            value={newFeature.titleAndroid}
            onChange={(event) => handleNewFeatureTitleChangeAndroid(event)}
          />
        </div>
        <div>
          <label>Android Message:</label>
          <textarea
            className="urgent-message-message"
            value={newFeature.messageAndroid}
            onChange={(event) => handleNewFeatureMessageChangeAndroid(event)}
          />
        </div>
        <div>
          <label>Call To Action Text:</label>
          <textarea
            className="urgent-message-title"
            value={newFeature.ctaText}
            onChange={(event) => handleNewFeatureCTAChange(event)}
          />
        </div>
        <div>
          <label>URL:</label>
          <textarea
            className="urgent-message-title"
            value={newFeature.url}
            onChange={(event) => handleNewFeatureURLChange(event)}
          />
        </div>
        <div>
          {newFeature.id !== "" ? (
            <button
              onClick={() => showConfirmRemove("newFeature")}
              className={`${newFeature.id !== "" ? "test-fail" : "test-passed"}`}
            >
              Remove New Feature Card
            </button>
          ) : (
            <button
              onClick={() => showConfirmPublish("newFeature")}
              className={`${newFeature.id !== "" ? "test-fail" : "test-passed"}`}
            >
              Publish New Feature Card
            </button>
          )}
        </div>
        <div>
          {confirmationModalValue !== "" && (
            <Popup
              message={"Are you sure you would like to apply this change?"}
              action={confirmationModalAction}
              value={confirmationModalValue}
              onCancel={onCancelConfirmation}
              onContinue={onContinueConfirmation}
            />
          )}
        </div>
        <div
          className={`${stormModeEnabled ? "test-fail" : "test-passed"}`}
        >
          <h2>
            Storm Mode:{" "}
            {stormModeEnabled ? "Enabled" : "Disabled"}
          </h2>
        </div>
        <div>
          <label>Storm Mode Templates:</label>
          <select onChange={handleStormModeTemplateChange}>
            <option value="Select a template">Select a template</option>
            {stormModeTemplates.map((template, index) => {
              const key = Object.keys(template)[0];
              return (
                <option key={index} value={key}>
                  {key}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <label>Storm Mode Custom Message:</label>
          <textarea
            className="urgent-message-message"
            type="text"
            value={stormModeMessage}
            onChange={handleStormModeMessageChange}
          />
          <div>
            <h3>
              {stormModeEnabled
                ? "Slide to exit Storm Mode"
                : "Slide to enter Storm Mode"}
            </h3>
            <input
              className="maintenance-mode-slider"
              type="range"
              min="0"
              max="100"
              value={stormSliderValue1}
              onInput={handleStormSliderChange}
              onMouseUp={handleStormSliderRelease}
              onTouchEnd={handleStormSliderEnd}
            />
            <input
              className="maintenance-mode-slider"
              type="range"
              min="0"
              max="100"
              value={stormSliderValue2}
              onInput={handleStormSliderChange2}
              onMouseUp={handleStormSliderRelease2}
              onTouchEnd={handleStormSliderEnd2}
            />
          </div>
        </div>
        <div>
            <label>Opco:</label>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="All Opcos"
                  checked={stormModeOpcos.includes("All Opcos")}
                  onChange={(event) => handleStormModeOpcoChange(event)}
                />
                All Opcos
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="4000"
                  checked={stormModeOpcos.includes("4000")}
                  onChange={(event) => handleStormModeOpcoChange(event)}
                />
                4000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="5000"
                  checked={stormModeOpcos.includes("5000")}
                  onChange={(event) => handleStormModeOpcoChange(event)}
                />
                5000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="6000"
                  checked={stormModeOpcos.includes("6000")}
                  onChange={(event) => handleStormModeOpcoChange(event)}
                />
                6000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="7000"
                  checked={stormModeOpcos.includes("7000")}
                  onChange={(event) => handleStormModeOpcoChange(event)}
                />
                7000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="8000"
                  checked={stormModeOpcos.includes("8000")}
                  onChange={(event) => handleStormModeOpcoChange(event)}
                />
                8000
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value="9000"
                  checked={stormModeOpcos.includes("9000")}
                  onChange={(event) => handleStormModeOpcoChange(event)}
                />
                9000
              </label>
            </div>
          </div>
  
      </div>
    );
  };
  